import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getVoucherInfo } from "../../services/ApiServices";
import showNotification from "../../services/NotificationService";
import { useDispatch } from "react-redux";
import { setData } from "../../redux/reducers/voucherSlice";
import { useState } from "react";
import Loader from "./Loader";

function AxisPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    code: Yup.string()
      .required("Code is required")
      .test("is-16-characters", "Code must be  16 characters", (value) => {
        return value && value.length === 16;
      }),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      let response = await getVoucherInfo(values?.code);
      if (response?.status == 200) {
        setIsLoading(false);
        navigate("/redeem/axisbank/products");
        dispatch(setData({ data: response?.data, code: values?.code }));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <div className="fixed top-0 w-full relative ">
        <NavBar />
      </div>
      <div className="flex justify-center text-center w-full mt-24 flex-col items-center">
        <h2 className="md:text-4xl font-extrabold text-xl">
          Enter code to redeem your voucher
        </h2>

        <div className="my-10 w-full flex flex-col items-center">
          <input
            type="text"
            name="code"
            maxLength={16}
            id=""
            className={`border border-b
      w-2/3 md:w-2/3 lg:w-[22%]
      outline-sky-500
      h-12 placeholder:text-gray-400
      placeholder:text-center text-center
      border-gray-400`}
            placeholder="xxxxxxxxxxxxxxx"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
          />

          {formik.touched.code && formik.errors.code ? (
            <div className="text-red-500 mt-2 text-center">
              {formik.errors.code}
            </div>
          ) : null}
        </div>
      </div>
      {loading && <Loader />}
      <div className="flex justify-center  flex-col items-center">
        <button
          onClick={() => formik.handleSubmit()}
          type="button"
          style={{ backgroundColor: "#97144d" }}
          className="  text-white px-14 py-2 font-medium text-xl rounded outline-none focus:outline-none"
        >
          Redeem
        </button>
      </div>

      <div className="flex justify-center mt-20 flex-col items-center">
        <div className="w-4/5 border-b border-gray-200 my-4"></div>
        <div className="py-2">
          <h6 className="font-bold">How to redeem?</h6>
          <ol className="list-decimal list-inside relative font-thin">
            <li className="relative">
              Enter the voucher code & click on Redeem
            </li>
            <li className="relative">Enter Name, Email & Mobile Number</li>
            <li className="relative">Click on Proceed</li>
            <li className="relative">
              Your voucher will be send via Email & SMS
            </li>
          </ol>
          <div className=" list-item-line-bottom"></div>
        </div>

        <div className="w-4/5 border-b border-gray-200 my-4"></div>
      </div>

      <Footer />
    </>
  );
}

export default AxisPage;
