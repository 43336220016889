import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoPow from "./Axis-bank/poweredby.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { restrictAlpha } from "../constant";
import { register, shoppersRegister } from "../services/ApiServices";
import showNotification from "../services/NotificationService";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/reducers/userSlice";

const LifeStyle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("/lifestyle");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  // State to track the active tab
  useEffect(() => {
    if (location?.pathname == "/lifestyle") {
      window.location="https://credencerewards.com/brand/lifestyle-offline-store-voucher"
    } else {
      window.location="https://credencerewards.com/brand/shopper-stop-offline-store-voucher"
    }
  }, [])
  

  const handleNavigate = () => {
    if (location?.pathname == "/lifestyle") {
      navigate("/lifestyle-checkout");
    } else {
      navigate("/shoppers-checkout");
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile is required"),

    accept: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      accept: false,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    let body = {
      name: values?.name,
      email: values?.email,
      mobile_no: values?.mobile,
    };
    try {
      let response =
        location?.pathname == "/lifestyle"
          ? await register(body)
          : await shoppersRegister(body);
      if (response?.status == 201) {
        if (response?.data?.stock?.length !== 0) {
          handleNavigate();
          showNotification("success", response?.data?.message);
          dispatch(setUser(response?.data));
        } else {
          showNotification("danger", "Currently out of stock!");
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="flex h-screen flex-1 bg-gray-100 flex-col justify-center px-6 py-12 lg:px-8 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center">
            <img
              className={
                location?.pathname == "/lifestyle"
                  ? "mx-auto h-12 w-32 border border-gray-800 shadow-xl  shadow-pink-700  rounded-full "
                  : "mx-auto h-10 w-auto  rounded-full "
              }
              onClick={() => navigate("/lifestyle")}

              src={"../assets/lifestyle.webp"}
              alt="Your Company"
            />
            <img
              className={
                location?.pathname == "/shoppers-stop"
                  ? "mx-auto h-10 w-auto border border-gray-800 bg-black shadow-xl shadow-sky-900 rounded-full "
                  : "mx-auto h-10 w-auto border border-gray-800 bg-black  rounded-full "
              }
              onClick={() => navigate("/shoppers-stop")}
              src={"../assets/shoppers-stop.png"}
              alt="Your Company"
            />
          </div>

          <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Your Details
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={formik.handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  maxLength={50}
                  autoComplete="email"
                  className="block w-full rounded-md border  border-gray-300 py-1.5 px-2  text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500">{formik.errors.name}</div>
                ) : null}
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  maxLength={50}
                  type="email"
                  autoComplete="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="block w-full rounded-md border  border-gray-300 py-1.5 px-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mobile Number
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="mobile"
                  name="mobile"
                  type="text"
                  autoComplete="current-password"
                  maxLength={10}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyPress={restrictAlpha}
                  value={formik.values.mobile}
                  className="block w-full rounded-md border  border-gray-300 py-1.5 px-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="text-red-500">{formik.errors.mobile}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div className="mt-2 flex items-center">
                <input
                  type="checkbox"
                  id="accept"
                  name="accept"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.accept}
                  className="text-indigo-600 h-4 w-4 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label
                  htmlFor="termsAndConditions"
                  className="ml-2 block text-sm text-gray-900"
                >
                  I accept the{" "}
                  <span
                    className="text-blue-500"
                    onClick={() => setIsModalOpen(true)}
                  >
                    terms and conditions
                  </span>
                </label>
              </div>
              {formik.touched.accept && formik.errors.accept ? (
                <div className="text-red-500">{formik.errors.accept}</div>
              ) : null}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5  text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
               Submit 
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-container">
            <div className="bg-white w-96 p-4 rounded-lg shadow-lg border border-gray-500">
              <div className="modal-header flex justify-between">
                <h2 className="text-xl font-bold">Terms & Condition</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-500 hover:text-red-500"
                >
                  Close
                </button>
              </div>
              <div className="w-full border-b border-gray-200 my-4"></div>

              <div className="modal-content">
                <p>
                  1. Gift Cards are redeemable at selected brands,Home Centre,
                  select Max and Melange stores in India.
                </p>
                <p>
                  2. This card is valid for one year from the date of issue and
                  can be used for multiple purchases until balance on card is
                  exhausted.
                </p>
                <p>
                  3. For balance enquiry/validity, SMS LSBAL space 16 digit card
                  number to 56767.
                </p>
                <p>
                  4. This card shall be deemed to be active upon loading of
                  minimum value of Rs. 500.
                </p>
                <p>
                  5.This Gift Card card can used at our offline stores only.{" "}
                </p>
                <p>
                  6. This card can be reloaded for a minimum value of Rs.50.
                </p>
                <p>
                  7. Brands reserves the right to amend the terms at its
                  discretion without prior notice.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LifeStyle;
