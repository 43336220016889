// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Import your chosen storage engine
import rootReducer from './reducers'; // Import your root reducer

const persistConfig = {
  key: 'root', // Key for the storage
  storage, // Storage engine
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
