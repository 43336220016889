import React from "react";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
const SolutionCard = ({ img, title, subtext, link }) => {
  return (
    <Tilt glareColor="5448E2" glareEnable={true}>
      <div className="bg-[#edfbfe] p-3 hover:shadow-2xl  rounded-xl border shadow-xl hover:bg-gradient-to-t from-[#594DF1] from-10% to-[#86D9C2] to-90% hover:text-white text-[#212529] transition-colors delay-150 w-[18rem] lg:w-[22rem] hover:transition-transform">
        <img className="rounded-md " src={img} alt="" />
        <div>
          <h2 className="text-xl font-semibold py-3 text-">{title}</h2>
          <p className="text-base">{subtext}</p>
          <Link to={link}>
            <button className=" CTA text-base mt-4 flex justify-center gap-2 items-center font-semibold font-Source text-black  bg-white my-3 border hover:shadow-xl transition-colors delay-100 shadow-cyan-300 rounded-lg p-2 px-3">
             Know More{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3.75 12H20.25"
                  stroke="#061C3D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 5.25L20.25 12L13.5 18.75"
                  stroke="#061C3D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </Tilt>
  );
};

export default SolutionCard;
