import React, { useState } from "react";

import NavBar from "./NavBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { restrictAlpha } from "../../constant";
import { redeemVoucher } from "../../services/ApiServices";
import showNotification from "../../services/NotificationService";
import { setData } from "../../redux/reducers/congratulationSlice";
import { login } from "../../redux/reducers/authSlice";
import Footer from "./Footer";
import { resetVoucherData as resetVoucherData } from "../../redux/reducers/voucherSlice";

function CheckoutPage() {
  const productDetail = useSelector((state) => state?.productDetail?.data);
  const amount = useSelector((state) => state?.productDetail?.amount);
  const code = useSelector((state) => state?.productDetail?.code);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),

    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address",
      )
      .required("Email is Required"),

    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile is required"),

    accept: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions",
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      accept: false,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    setLoading(true);
    let body = {
      credence_code: code,
      provider_id: productDetail?.id,
      client_id: 1,
      name: values?.name,
      email: values?.email,
      mobile_no: values?.mobile,
    };
    try {
      let response = await redeemVoucher(body);

      setLoading(false);

      if (response?.status == 200) {
        navigate("/congratulation");
        dispatch(
          setData({
            data: response?.data,
            image: productDetail?.image,
          }),
        );
        dispatch(resetVoucherData());
        showNotification("success", response?.data?.message);
      } else {
        // You could add more logic here to handle other status codes
      }
    } catch (error) {
      // Potential error handling could go here
    }
  };
  return (
    <>
      <div className="fixed top-0 w-full relative ">
        <NavBar />
      </div>
      <div className="flex flex-col items-center mt-20 justify-center my-10">
        <h2 className="md:text-4xl font-extrabold text-xl">Congratulations!</h2>

        <h2 className="font-thin py-10 text-center ">
          {` Please fill in the below information to receive your - ₹ ${amount} voucher`}
        </h2>
        {/* {loading ? <CustomLoader icon={<Spinner />} /> : null} */}
        {/* {error ? (
        <CustomLoader
          icon={<CloseIcon onClick={() => setError(null)} />}
          text={error}
        />
      ) : null} */}
        <div className="flex w-[95%] flex-col lg:flex-row items-center lg:items-start mx-auto lg:mr-52">
          <div className="flex flex-col w-full justify-start gap-y-6 items-center ">
            <div class="align-top flex items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-8 h-8 text-sky-600 cursor-pointer"
                onClick={() => navigate("/redeem/axisbank/products")}
              >
                <path d="M19 12H3M3 12l8-7M3 12l8 7" />
              </svg>
              <p className="text-sky-600">Change</p>
            </div>
            <div>
              <div
                class="w-full max-w-sm bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700
    cursor-pointer
    hover:shadow-lg
    transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110

    "
              >
                <img
                  className="p-8 rounded-t-lg h-44"
                  src={productDetail?.logo_link}
                  alt="product"
                />
              </div>
            </div>
          </div>

          {/* form */}
          <div className=" w-[90%] ">
            <div className="flex flex-col  justify-start">
              <label className="text-sm mb-2">Name</label>
              <input
                maxLength={50}
                name="name"
                className="border-2  border-gray-400 rounded-md p-2  w-60%"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : null}

              <label className="text-sm mb-2">Email</label>
              <input
                maxLength={50}
                name="email"
                className="border-2 border-gray-400 rounded-md p-2  w-60%"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 mb-2">{formik.errors.email}</div>
              ) : null}
              <label className="text-sm mb-2">Phone</label>
              <input
                maxLength={10}
                type="text"
                pattern="[0-9]{10}"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyPress={restrictAlpha}
                value={formik.values.mobile}
                name="mobile"
                className="border-2 border-gray-400 rounded-md p-2  w-60%"
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="text-red-500 mb-2">{formik.errors.mobile}</div>
              ) : null}
              <div className="flex items-center mt-2 ">
                <input
                  type="checkbox"
                  name="accept"
                  className="mr-2 h-6 w-6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.accept}
                />
                <label className="text-sm">
                  I agree to the
                  <span
                    onClick={() => dispatch(login())}
                    className="text-blue-500 hover:underline"
                  >
                    Terms and Conditions
                  </span>
                </label>
              </div>
              {formik.touched.accept && formik.errors.accept ? (
                <div className="text-red-500">{formik.errors.accept}</div>
              ) : null}
              <p className="text-sm text-gray-500 mt-2">
                Your information is safe with us. We will not share your data
                with any third party.
              </p>
              <div className="flex justify-end mt-2">
                {loading ? (
                  <button
                    type="button"
                    className=" bg-[#97144D] text-white px-14 py-2 rounded outline-none focus:outline-none"
                  >
                    Proceeding...
                  </button>
                ) : (
                  <button
                    type="button"
                    className=" bg-[#97144D] text-white px-14 py-2 rounded outline-none focus:outline-none"
                    onClick={formik.handleSubmit}
                  >
                    Proceed
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4"></div>
        <Footer />
      </div>
    </>
  );
}

export default CheckoutPage;
