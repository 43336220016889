import * as api from "../request";

  export const register = async (body) => {
    return await api.PostReq(`/lifestyle/init`,body);
  };
  export const shoppersRegister = async (body) => {
    return await api.PostReq(`/shoppers-stop/init`,body);
  };
  export const addLifestyleItem = async (body) => {
    return await api.PostReq(`/lifestyle/additems`,body);
  };
  export const addShopersItem = async (body) => {
    return await api.PostReq(`/shoppers-stop/additems`,body);
  };
  export const getVoucherInfo = async (code) => {
    return await api.GetReq(`/api/check-credence-code/?credence_code=${code}&client_id=1`);
  };
  export const redeemVoucher = async (body) => {
    return await api.PostReq(`/api/redeem-voucher`,body);
  };
  
 
  