import React, { useState, useEffect } from "react";
import logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="bg-white relative z-50 w-full">
        <nav className="md:flex justify-between hidden  items-center lg:px-20  p-4 z-30 relative bg-white">
          {/* <ul className="flex font-Source antialiased gap-5 items-center text-base font-semibold">
            <li>Products</li>
            <li>Resources</li>
          </ul> */}
          <Link to={"/"}>
            <img className="w-40 h-12" src={logo} alt="Logo" />
          </Link>

          <div className="flex justify-evenly items-center gap-20">
            <ul className="flex justify-between gap-10 items-center">
              <Link to={"/"}>
                <li className="relative cursor-pointer font-semibold text-[#838E9E] hover:text-[#594DF1]">
                  Home
                </li>
              </Link>
              <Link to="/about-us">
                <li className="relative cursor-pointer font-semibold text-[#838E9E] hover:text-[#594DF1]">
                  About Us
                </li>
              </Link>

              <div className="relative">
                <li
                  className="relative cursor-pointer font-semibold text-[#838E9E] hover:text-[#594DF1]"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Products
                </li>
                {isDropdownOpen && (
                  <ul
                    className="absolute top-full left-0 bg-white shadow-md p-2 w-44 font-semibold rounded-md"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link to="/squid">
                      <li className="p-2 text-[#838E9E] hover:text-[#594DF1]">
                        Squid
                      </li>
                    </Link>
                    <Link to="/credence-visa-card">
                      <li className="p-2 text-[#838E9E] hover:text-[#594DF1]">
                        Credence Card
                      </li>
                    </Link>
                    <Link to="/credence-code">
                      <li className="p-2 text-[#838E9E] hover:text-[#594DF1]">
                        Credence Code
                      </li>
                    </Link>
                    {/* <Link to="/enquiry">
                      <li className="p-2 text-[#838E9E] hover:text-[#594DF1]">
                        Bulk order
                      </li>
                    </Link> */}
                    {/* <a
                      href="#"
                      className="p-2 text-[#838E9E] hover:text-[#594DF1]"
                    >
                      Credence Code
                    </a> */}
                  </ul>
                )}
              </div>
              <Link to="/enquiry">
                <li className="relative cursor-pointer font-semibold text-[#838E9E] hover:text-[#594DF1]">
                  Bulk order
                </li>
              </Link>
            </ul>
          </div>
          <Link to={"/contact-us"}>
            <button className="text-base font-semibold font-Source text-white bg-[#594DF1] border  rounded-full p-2 px-3">
              Book a demo
            </button>
          </Link>
        </nav>
        {/* Mobile */}
        <div className="navbar desktop">
          <div className="navbar-start">
            <div className="dropdown">
              <div tabIndex={0} role="button" className="btn btn-ghost desktop">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm  dropdown-content bg-purple-100 rounded-box z-[1] opacity-100 mt-3 w-52 p-2 shadow"
              >
                <Link to={"/"}>
                  <li>
                    <a>Home</a>
                  </li>
                </Link>
                <Link to="/about-us">
                  <li>
                    <a>about us</a>
                  </li>
                </Link>
                <Link to="/squid">
                  <li>
                    <a>squid</a>
                  </li>
                </Link>
                <Link to="/credence-visa-card">
                  <li>
                    <a>Credence Card</a>
                  </li>
                </Link>
                <Link to="/credence-code">
                  <li>
                    <a>Credence Code</a>
                  </li>
                </Link>

                <li>
                  <Link
                    to={"/enquiry"}
                    className="p-1 px-2 mx-2.5 mt-1 w-fit text-sm font-semibold bg-[#594DF1] text-white rounded-full"
                  >
                    Book a demo
                  </Link>
                </li>
              </ul>
            </div>
            {/* <a className="btn btn-ghost text-xl">daisyUI</a> */}
          </div>
          <Link to={"/"}>
            <img className="w-64 h-12" src={logo} alt="Logo" />
          </Link>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <a>Item 1</a>
              </li>
              <li>
                <details>
                  <summary>Parent</summary>
                  <ul className="p-2">
                    <li>
                      <a>Submenu 1</a>
                    </li>
                    <li>
                      <a>Submenu 2</a>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <a>Item 3</a>
              </li>
            </ul>
          </div>
          <div className="navbar-end"></div>
        </div>
      </div>
    </>
  );
};

export default Header;
