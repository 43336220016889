import React from "react";
import FlexibilityCard from "./Cards/FlexibilityCard";
import recipient from "../assets/recipient.gif";
import yourself from "../assets/yourself.gif";
import p2p from "../assets/p2p.gif";
const Flexibilty = () => {
  return (
    <div className="bg-[#594DF1]  py-8">
      <h3
        className="text-center pt-2
       text-white  font-Source font-semibold text-2xl"
      >
        Total Flexibility
      </h3>
      <p className="text-4xl text-white py-1.5 font-bold text-center">
        You choose what to give (or not!)
      </p>
      <p className="text-center w-[90%] md:w-[40%] text-white font-Source font-medium py-2 text-base mx-auto">
        Selecting gift or reward items doesn’t have to be up to you, unless you
        want it to be. Choose your method of giving:
      </p>

      <div className="flex justify-center flex-col lg:flex-row items-center gap-5">
        <FlexibilityCard
          img={recipient}
          title={"Recipient’s Choice"}
          subtext={
            "Give recipients our Credence Code, and let them choose the reward they want. "
          }
        />
        <FlexibilityCard
          img={yourself}
          title={"For Yourself"}
          subtext={"Buy for yourself from our massive gift-card catalogue"}
        />
        <FlexibilityCard
          img={p2p}
          title={"Peer-to-Peer"}
          subtext={
            "Let employees reward one another with gift-cards they can redeem for cool stuff."
          }
        />
      </div>
    </div>
  );
};

export default Flexibilty;
