import React from 'react'

const Page404 = () => {
  return (
    <div className='  mt-80'>
         <h1 className='text-primary-100 text-center text-3xl'>404 - Not Found</h1>
      <p className='text-primary-100 text-center text-xl'>The page you are looking for does not exist.</p>
    </div>
  )
}

export default Page404
