import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProduct } from "../../redux/reducers/singleProductSlice";
import sortIcon from "./Z-A-1.png";
import sortIcon1 from "./Z-A.png";

function Products() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let products = useSelector((state) => state?.vouchers?.data?.data);
  let code = useSelector((state) => state?.vouchers?.data?.code);

  const [filterProduct, setFilterProduct] = useState(products?.data); // Bug 1: filterProduct might be undefined if products is undefined
  const [sort, setSort] = useState("ASC");

  console.log(products);

  const handleSearch = (searchQuery) => {
    const filteredProducts = products?.data?.filter((product) =>
      product?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilterProduct(filteredProducts); // Bug 2: Filtering on products.data instead of filterProduct
  };

  const sortAscending = () => {
    setSort("ASC");
    const sorted = [...filterProduct].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setFilterProduct(sorted);
  };

  const sortDescending = () => {
    setSort("DESC");
    const sorted = [...filterProduct].sort((a, b) =>
      b.name.localeCompare(a.name)
    );
    setFilterProduct(sorted);
  };

  // Bug 3: If products change, filterProduct won't automatically update
  useEffect(() => {
    setFilterProduct(products?.data || []); // Bug 4: Should initialize with an empty array if products is undefined
  }, [products]);

  return (
    <div className="h-screen">
      <div className="fixed top-0 w-full relative">
        <NavBar />
      </div>
      <div className="flex justify-center mt-20 flex-col items-center">
        <h2 className="md:text-2xl text-center font-semibold text-xl">
          Choose a voucher | Amount - ₹{products?.amount}
        </h2>

        <div className="w-full flex gap-20 items-center justify-center">
          <div className="flex items-center w-[50%] mt-5 justify-center flex-col">
            <label htmlFor="voice-search" className="sr-only"></label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                >
                  <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
                </svg>
              </div>
              <input
                type="text"
                id="search-by-name"
                className="py-3 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search by keywords"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            {sort === "ASC" ? (
              <img
                className="rounded-md mt-3 bg-sky-600"
                src={sortIcon}
                style={{ height: "25px", padding: "4px", width: "25px" }}
                onClick={sortDescending}
              />
            ) : (
              <img
                className="rounded-md mt-3 bg-sky-600"
                src={sortIcon1}
                style={{ height: "25px", padding: "4px", width: "25px" }}
                onClick={sortAscending}
              />
            )}
          </div>
        </div>
      </div>
      <div className="m-10 mx-10 flex flex-col gap-y-10 md:flex-row gap-x-10 flex-wrap justify-center items-center">
        {filterProduct?.length !== 0 ? (
          <div className="flex flex-wrap gap-5 justify-center">
            {filterProduct?.map((data, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-4 px-4 md:px-8 p-5 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700
                cursor-pointer
                hover:shadow-lg
                transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                mx-2"
                onClick={() => {
                  dispatch(
                    setProduct({
                      data: data,
                      amount: products?.amount,
                      code: code,
                    })
                  );
                  navigate("/redeem/axisbank/checkout");
                }}
              >
                <img
                  className="rounded-t-lg w-full"
                  src={data?.logo_link}
                  alt="product"
                />
              </div>
            ))}
          </div>
        ) : (
          <span className="mx-auto">No Data Found!</span> // Bug 5: If search query is cleared, no data is displayed
        )}
      </div>
    </div>
  );
}

export default Products;
