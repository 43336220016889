import React from "react";

const FlexibilityCard = ({ img, title, subtext }) => {
  return (
    <div className="bg-white w-[90%] lg:w-[30%] lg:h-[35rem] p-5 rounded-xl">
      <div>
        <h3 className="text-center text-3xl font-semibold">{title}</h3>
        <p className="text-center text-lg w-[70%] mx-auto py-2">{subtext}</p>
      </div>
      <img className="w-[80%] mx-auto" src={img} alt={title} />
    </div>
  );
};

export default FlexibilityCard;
