import React, { useState } from "react";
import Footer from "./Axis-bank/Footer";
import { useDispatch, useSelector } from "react-redux";
import showNotification from "../services/NotificationService";
import { addLifestyleItem, addShopersItem } from "../services/ApiServices";
import { useLocation, useNavigate } from "react-router-dom";
import PowerLogo from "./Axis-bank/poweredby.png";
import { clearUser } from "../redux/reducers/userSlice";

const LifestyleCheckout = () => {
  const stockDetail = useSelector((state) => state?.user?.user);
  const location = useLocation();

  const [stockQuantities, setStockQuantities] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQuantityChange = (id, newQuantity) => {
    if (newQuantity === 0 || newQuantity == "") {
      // If the new quantity is zero, remove the object from stockQuantities
      const updatedQuantities = stockQuantities.filter(
        (stock) => stock.type !== id,
      );
      setStockQuantities(updatedQuantities);
    } else {
      // Check if the stock id exists in the current stockQuantities
      const stockIndex = stockQuantities.findIndex(
        (stock) => stock.type === id,
      );

      if (stockIndex !== -1) {
        // If the stock exists, update its quantity
        const updatedQuantities = [...stockQuantities];
        updatedQuantities[stockIndex].qty = newQuantity;
        setStockQuantities(updatedQuantities);
      } else {
        // If the stock doesn't exist, add it to stockQuantities
        setStockQuantities((prevQuantities) => [
          ...prevQuantities,
          { type: id, qty: newQuantity },
        ]);
      }
    }
  };

  const isQuantityValid = (quantity, maxAllowedQuantity) => {
    return quantity <= maxAllowedQuantity;
  };
  const calculateTotalQuantity = () => {
    return stockQuantities.reduce((total, stock) => total + stock.qty, 0);
  };

  const calculateTotalAmount = () => {
    return stockQuantities.reduce(
      (total, stock) => total + stock.qty * stock.type,
      0,
    );
  };

  const handlePay = async () => {
    if (stockQuantities?.length !== 0) {
      setIsLoading(true);
      let body = {
        items: stockQuantities,
        transaction_id: stockDetail?.data?.id,
      };
      try {
        let response =
          location?.pathname == "/lifestyle-checkout"
            ? await addLifestyleItem(body)
            : await addShopersItem(body);
        if (response?.data?.status == 200) {
          setIsLoading(false);
          displayRazorpay(response?.data?.data);
        } else {
          setIsLoading(false);
          if (location?.pathname == "/lifestyle-checkout") {
            navigate("/lifestyle");
          } else {
            navigate("/shoppers-stop");
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (location?.pathname == "/lifestyle-checkout") {
          navigate("/lifestyle");
        } else {
          navigate("/shoppers-stop");
        }
      }
    } else {
      showNotification("danger", "Please select a voucher");
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  let amount = calculateTotalAmount() * (1 - stockDetail?.data?.discount / 100);

  async function displayRazorpay(data) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }

    // const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_SEC_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: "INR",
      name: "Credence Rewards",
      description:
        location?.pathname == "/lifestyle-checkout"
          ? "Digital gift cards for everyone"
          : "Shoppers Stop Gift Cards",
      image: "../../assets/CR Logo.png",
      order_id: data?.id,
      handler: async function (response) {
        navigate("/");
        dispatch(clearUser());
      },
      prefill: {
        name: data?.name,
        email: data?.email,
        contact: data?.mobile,
      },

      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="bg-gray-50  h-screen">
      <div className=" flex pt-20 flex-1 flex-col bg-gray-50 justify-center px-6 py-12 lg:px-8 ">
        <div className="flex flex-col justify-center  flex-warp">
          <div className="bg-white w-96 mx-auto p-2 rounded-t-lg">
            <img
              className="w-52 mx-auto"
              src={
                location?.pathname === "/lifestyle-checkout"
                  ? "../assets/lifestyle.webp"
                  : "/assets/shoppers-stop.png"
              }
              alt=""
            />
          </div>
          <div className="flex w-96 mx-auto justify-between bg-white text-xl px-4">
            <p className="">Denominations</p>
            <p>Quatity</p>
          </div>
          {stockDetail?.stock?.length !== 0 ? (
            stockDetail?.stock?.map((item, index) => {
              return (
                <div
                  className="bg-white w-96 mx-auto flex  items-center justify-between p-4  "
                  key={index}
                >
                  <div className=" relative">
                    <img
                      src="../assets/blank_gift_card.png"
                      alt="Product 1"
                      className="w-24 h-16"
                    />
                    <div className="absolute top-1  right-1.5 text-center text-sm font-semibold font-poppins text-white">
                      Rs {item?.amount}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 mt-2">
                    {/* <label className="text-gray-600">Quantity:</label> */}
                    <input
                      defaultValue={0}
                      min={0}
                      max={item?.vouchers}
                      type="number"
                      className="ml-2 w-20 py-1 px-2 rounded-md border border-gray-300 "
                      onChange={(e) =>
                        handleQuantityChange(
                          item?.amount,
                          e.target.value ? parseInt(e.target.value) : "",
                        )
                      }
                    />
                    {isQuantityValid(
                      stockQuantities?.length != 0
                        ? stockQuantities[index]?.qty
                        : 0,
                      item?.vouchers,
                    ) ? (
                      <></>
                    ) : (
                      stockQuantities[index]?.type == item?.amount && (
                        <span className="text-red-500"> (Exceeds limit)</span>
                      )
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <>No Stocks Available!</>
          )}
        </div>

        <div className="bg-white p-4  w-96 mx-auto rounded-b-lg ">
          <h2 className="text-xl font-semibold">Summary</h2>
          <div className="mt-4">
            <div className="flex justify-between">
              <span className="text-lg font-poppins">Total Quantity:</span>
              <span className="text-lg font-poppins">
                {calculateTotalQuantity()}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-lg font-poppins">Discount:</span>
              <span className="text-lg font-poppins">
                {stockDetail?.data?.discount} %
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-lg font-poppins">Total Amount:</span>
              <span className="text-lg font-poppins">
                {calculateTotalAmount() *
                  (1 - stockDetail?.data?.discount / 100)}
              </span>
            </div>
            <div className="mt-4 flex justify-center items-center">
              {loading ? (
                <button
                  className="bg-indigo-800 text-white font-semibold rounded-md  px-5 py-2 hover:bg-indigo-600"
                  disabled
                >
                  Processing...
                </button>
              ) : (
                <button
                  className="bg-indigo-800 text-white font-semibold rounded-md  px-5 py-2 hover:bg-indigo-600"
                  onClick={handlePay}
                >
                  Pay
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <footer className="py-4 text-center mt-28 bg-gray-50 left-0 bottom-0 w-full">
        <div className="flex justify-end items-baseline">
          <span className="text-[12px] mr-2 text">Powered by </span>
          <img
            src={PowerLogo}
            alt="credence-logo"
            className="h-14 w-24 md:mr-24 mr-4"
          />
        </div>
        <p className="text-sm text-slate-400">
          Coyright &copy; 2025. All rights reserved by Credence rewards.
        </p>
      </footer>
    </div>
  );
};

export default LifestyleCheckout;
