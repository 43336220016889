import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const MainLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col justify-between ">
      <Header />
      <main className="">{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
