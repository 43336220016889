import * as api from "../requests";

export const sendDetails = async (body) => {
  return await api.PostReq(`/api/brands/landing-b-2-b-contact`, body);
};

export const getbrands = async () => {
  return await api.GetReq(`/api/brands/landing-page-brands`);
};

export const  addContactUs= async (body) => {
  return await api.PostReq(`/api/brands/api/contact-us`,body);
};
