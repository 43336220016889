import React from "react";
import Marquee from "react-fast-marquee";
import axis from "../assets/Axis.png";
import Burda from "../assets/Burda.webp";
import goderj from "../assets/godrej.png";
import HCL from "../assets/HCL.png";
import sunrise from "../assets/sunrise.png";
import Tata from "../assets/Tata.png";
const Companies = () => {
  return (
    <div className=" my-12 lg:my-32  " style={{ width: "100%" }}>

      <div className="flex pt-2 justify-center lg:px-28 items-center">
        <div>
     
          <h2 className="text-center font-semibold text-xl  ">
            We have <span className="text-[#594DF1] font-semibold">23k+</span>{" "}
            Satisfied & Trusted Customers
          </h2>
        </div>
      </div>

      <Marquee className="lg:w-[40%] mx-auto" gradient={true} gradientWidth={80}>
        <div className="flex  justify-between mx-32 gap-64 items-center">
          <img className="" width={120} height={120} src={Burda} alt="" />
          <img width={120} height={120} src={axis} alt="" />
          <img className="" width={120} height={120} src={goderj} alt="" />

          <img width={120} height={120} src={HCL} alt="" />
          <img width={120} height={120} src={sunrise} alt="" />
         
        </div>
      </Marquee>
    </div>
  );
};

export default Companies;
