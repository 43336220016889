import React from "react";
import MainLayout from "../layout/MainLayout";
import Flexibilty from "../Components/Flexibilty";
import squidoffer from "../assets/squiidoffer.webp";
import JoinUs from "../Components/JoinUs";
import HelmetWrapper from "../Components/HelmetWrapper";
const Squid = () => {
  return (
    <MainLayout>
      <HelmetWrapper
        title="Squid Rewards - Credence Rewards"
        description="Explore the Squid Rewards offered by Credence Rewards. Discover how you can earn and redeem Squid Points for exclusive rewards and benefits. Join our program today to start enjoying the perks!"
        keywords="Squid rewards, Credence Rewards, earn Squid points, redeem Squid rewards, exclusive benefits, loyalty program,Email campaign, Squid Points, corporate gifting"
        ogTitle="Squid Rewards - Credence Rewards"
        ogDescription="Explore the Squid Rewards offered by Credence Rewards. Discover how you can earn and redeem Squid Points for exclusive rewards and benefits. Join our program today to start enjoying the perks!"
        ogUrl="https://credencerewards.co.in/squid"
        ogImage={squidoffer} // Replace with the actual path to your image
        twitterTitle="Squid Rewards - Credence Rewards"
        twitterDescription="Explore the Squid Rewards offered by Credence Rewards. Discover how you can earn and redeem Squid Points for exclusive rewards and benefits. Join our program today to start enjoying the perks!"
        twitterImage={squidoffer} // Replace with the actual path to your image
        canonicalUrl="https://credencerewards.co.in/squid"
      />
      <div className="py-10">
        <div className="md:px-10   md:w-[95%] rounded-2xl py-5 mx-auto">
          <h1 className="text-black text-center text-6xl font-bold">Squid</h1>
          <p className="text-lg font-source font-medium py-10 px-3 md:px-24 text-justify">
            Welcome to the Squid Dashboard, your all-in-one solution for
            managing bulk gifting and email campaigns with ease and efficiency.
            Designed with businesses in mind, our dashboard streamlines the
            process of sending digital gift cards to employees, clients, and
            partners, ensuring that your recognition and appreciation efforts
            are both impactful and effortless.
          </p>
        </div>
        <div>
          <img className="md:w-[80%] mb-28 mx-auto" src={squidoffer} alt="corporate gifting solution squid" />
        </div>
        <div>
          <Flexibilty />
        </div>
        <JoinUs
          title={"Get your Hands on Squid"}
          subtext={
            " Join the Credence Rewards community today and experience the joy of giving and receiving with ease and confidence."
          }
          btntext={"Book a demo"}
          link={"/contact-us"}
        />
      </div>
    </MainLayout>
  );
};

export default Squid;
