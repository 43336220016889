import React from "react";
import crcode from "../assets/crcode.webp";
import MainLayout from "../layout/MainLayout";
import entercode from "../assets/enterCode.webp";
import select from "../assets/select.webp";
import details from "../assets/details.webp";
const Crcode = () => {
  return (
    <MainLayout>
      <div className=" flex justify-evenly flex-wrap lg:flex-nowrap mt-12 relative z-30 ">
        <div className="px-2 md:px-28 w-[99%]">
          <h1 className="text-3xl xl:text-6xl text-center mt-6 md:mt-20 lg:text-left font-semibold">
            Credence Code by Credence Rewards
          </h1>

          <div className="text-justify text-base xl:text-xl   w-fit  py-9 rounded-xl mt-9 font-semibold ">
            Unlock exclusive benefits and special offers with your Credence
            Code! Simply enter your code in the designated redemption field on
            our website to gain access to a world of premium content, discounts,
            and personalized services.{" "}
            <p className="pt-4">
              {" "}
              on’t miss out—redeem your code today and start enjoying the
              exceptional perks that come with being a valued member of our
              community.
            </p>
          </div>
        </div>
        <div>
          <img
            className="hidden lg:block lg:w-[90%]"
            src={crcode}
            alt="Credence Visa Card by NSDL Payments Bank"
          />
          {/* <Nsdl3d /> */}
        </div>
      </div>
      <div className="bg-[#F8F8FE] py-14 relative z-30">
        <div className="text-center text-3xl md:text-4xl font-semibold mb-3 ">
          How to redeem Reward Code
        </div>
        <p className="text-center px-5 md:px-52 font-semibold text-xl  relative z-40">
          Experience the convenience and security of the Credence Visa Card,
          designed to meet all your payment needs, whether you're an individual
          or a corporate customer.
        </p>
        <div
          className="flex justify-center flex-wrap md:flex-nowrap gap-12 py-20 items-start
         overflow-x-hidden no-scrollbar px-6 md:px-40  mt-12 "
        >
          <div className="bg-white shadow-md p-5  rounded-xl">
            <img src={entercode} alt="" className="w-8" />
            <h3 className="font-semibold text-lg">Enter code</h3>
            <p>Enter the voucher code & click Redeem button.</p>
          </div>
          <div className="bg-white shadow-md p-5  rounded-xl">
            <img src={select} alt="" className="w-8" />
            <h3 className="font-semibold text-lg">Select your gift card</h3>
            <p>Enter the voucher code & click Redeem button.</p>
          </div>
          <div className="bg-white shadow-md p-5  rounded-xl">
            <img src={details} alt="" className="w-8" />
            <h3 className="font-semibold text-lg">Enter your details</h3>
            <p>Enter Name, Email, Mobile to get your voucher.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Crcode;
