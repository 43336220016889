import React from "react";
import PowerLogo from "./poweredby.png";

function Footer() {
  return (
    <>
      <footer className="py-4 text-center  left-0 bottom-0 w-full">
        <div className="flex justify-end items-baseline">
          <span className="text-[12px] mr-2 text">Powered by </span>
          <img
            src={PowerLogo}
            alt="credence-logo"
            className="h-14 w-24 md:mr-24 mr-4"
          />
        </div>
        <p className="text-sm text-slate-400">
          Coyright &copy; 2025. All rights reserved by Credence rewards.
        </p>
      </footer>
    </>
  );
}

export default Footer;
