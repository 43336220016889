import axios from "axios";

import showNotification from "./service/Notification";

export const http = axios.create({
  baseURL: "https://panel.credencerewards.com",
});

http.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        showNotification("default", "Access Denied");
      } else if (error.response.status === 401) {
        showNotification("default", "Permission Required");
      } else {
        return Promise.reject(error);
      }
    } else {
      console.error("Network Error:", error.message);
      return Promise.reject(error);
    }
  }
);
