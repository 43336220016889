import React from "react";
import logo from "../../logo-white.png";
import CustomDarw from "./CustomDrawer"
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/authSlice";

function NavBar() {
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch =useDispatch()
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }
  return (
    <div style={{ width: "100%", height: 100, margin: "0px" }}>
    <div className="relative flex justify-end m-0 p-0"
      style={{ backgroundColor: "#97144d", width: "100%", height: "35%" }}
    >
    <div className="md:flex m-0 p-0 gap-x-4 hidden  font-semibold text-white mr-14">
    <a href="mailto:support@credencerewards.com">Contact Support</a>
      <a  onClick={() =>dispatch(login()) }>Terms {" "}& {" "} Conditions</a>
      
    </div>
  {/* mobile menu */}
    <div className="md:hidden absolute top-7  left-0.5">
          <button className="text-white px-2 py-1" onClick={toggleDrawer}>
          <CustomDarw
              isOpen={isOpen}
              setIsOpen={toggleDrawer}
              direction="left"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 448 512"
              fill="#ffffff"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </button>
        </div>
    </div>
    <div className="w-[70%] m-0 md:w-[40%] lg:w-[25%]" style={{ backgroundColor: "#97144d", height: 60 }}>
      <img className="absolute w-40 top-6 left-12 md:left-24 lg:left-[5.5rem]" src={logo} alt="" />
    
      <img
        src="https://nbpg.axisbank.co.in/axispaymentgateway/assets/images/menucurve.svg"
        alt=""
        style={{ float: "right", marginRight: "-56.5px",height: 60  }}
      />
    </div>
  </div>
    //     <div
    //     className="flex text-white justify-end h-20 relative md:h-28"
    //     style={{
    //       backgroundImage: "url('./axis-logo.jpeg')",
    //       backgroundPosition: "left center",
    //       backgroundSize: "cover",
    //       background: "rgba(134, 31, 65, 0.8)", // Adjust the opacity and color as needed

    //       // Dark overlay style
    //       "::before": {
    //         content: '""',
    //         position: "absolute",
    //         top: 0,
    //         left: 0,
    //         width: "100%",
    //         height: "100%",
    //         background: "rgba(134, 31, 65, 0.8)", // Adjust the opacity and color as needed
    //       },
    //     }}
    //   >
    // <img src={logo} height={"118px"} className='nav-img'/>
    //   </div>
   
  );
}

export default NavBar;


