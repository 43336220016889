import React from "react";
import MainLayout from "../layout/MainLayout";

import ContactForm from "../Components/ContactForm";
import HelmetWrapper from "../Components/HelmetWrapper";
export const ContactUS = () => {
  return (
    <MainLayout>
      <HelmetWrapper
        title="Contact Us - Credence Rewards"
        description="Reach out to Credence Rewards through our contact page. Contact us for any queries, partnership opportunities, or general inquiries about our rewards program. We're here to assist you."
        keywords="Credence Rewards contact, contact us, customer support, partnership opportunities, rewards program inquiries, general inquiries"
        ogTitle="Contact Us - Credence Rewards"
        ogDescription="Reach out to Credence Rewards through our contact page. Contact us for any queries, partnership opportunities, or general inquiries about our rewards program. We're here to assist you."
        ogUrl="https://credencerewards.co.in/contact-us"
        ogImage=""
        twitterTitle="Contact Us - Credence Rewards"
        twitterDescription="Reach out to Credence Rewards through our contact page. Contact us for any queries, partnership opportunities, or general inquiries about our rewards program. We're here to assist you."
        twitterImage=""
        canonicalUrl="https://credencerewards.co.in/contact-us"
      />
      <h1 className=" text-2xl lg:text-6xl text-indigo-800 font-semibold md:mt-12  text-center">
        Get in touch to Book a Demo
      </h1>
      <h2 className="text-center text-2xl lg:text-6xl pt-3"> </h2>
      <ContactForm />
    </MainLayout>
  );
};
