import React from "react";
import fast from "../assets/fast.webp";
import users from "../assets/users.webp";
import safe from "../assets/safe.webp";
import Marquee from "react-fast-marquee";
import user1 from "../assets/user1.jpg";
import user2 from "../assets/user2.jpg";
import user3 from "../assets/user3.jpg";
import Testimonial from "./Testimonial";
const Trust = () => {
  const testimonials = [
    {
      name: "Jane Doe",
      image: user1,
      color: "bg-blue-100",
      review:
        "Credence Rewards has completely transformed the way we handle corporate gifts. The platform is user-friendly and offers a wide variety of options. Our employees love it!",
    },
    {
      name: "John Smith",
      image: user2,
      color: "bg-pink-100",
      review:
        "The best gifting solution I have ever used! Credence Rewards makes it so easy to send personalized gifts to our clients. Highly recommended!",
    },
    {
      name: "Alice Johnson",
      image: user3,
      color: "bg-purple-100",
      review:
        "Less than 24h turn around. Easy communication. Did exactly as offered, all around a perfect experience.",
    },
  ];
  return (
    <div className="">
      <div className=" font-Source font-semibold text-[39px] my-12 lg:my-14  lg:w-[90%] mx-auto  rounded-lg">
        <ul className="flex-col items-center justify-center gap-3  text-[#0f011b]">
          <li className="flex text-center text-xl md:text-3xl items-center my-10 justify-center ">
            Why Our Client Trust Us ?
          </li>
          <Marquee
            className="hidden  md:flex"
            gradient={true}
            direction="right"
            gradientWidth={120}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial
                key={index}
                image={testimonial.image}
                name={testimonial.name}
                review={testimonial.review}
                color={testimonial.color}
              />
            ))}
          </Marquee>
          {/* <Marquee
            className=" sm:hidden"
            gradient={true}
            direction="right"
            gradientWidth={50}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial
                key={index}
                image={testimonial.image}
                name={testimonial.name}
                review={testimonial.review}
                color={testimonial.color}
              />
            ))}
          </Marquee> */}
        </ul>
      </div>
    </div>
  );
};

export default Trust;
